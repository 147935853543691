<template>
  <div class="layout" id="app">
    <!--    <main-header></main-header>-->

    <main>
      <router-view></router-view>
    </main>
    <NavBar />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { whoami } from "@/api/auth";

export default {
  name: "app",
  components: {
    NavBar,
  },
  async created() {
    const user = await whoami();
    this.$store.commit("setUser", user.me);
    this.$store.commit("initBasket");
  },
};
</script>

<style lang="scss">
.layout {
  @apply h-full flex flex-col;
  .route-content {
    @apply h-full overflow-y-auto;
  }
}

#app > main {
  max-width: 1200px;
  @apply h-full overflow-y-auto px-5 w-full box-border;
}

@media screen and (min-width: 720px) {
  .layout {
    @apply flex-row-reverse justify-end;
    .route-content {
      max-width: 700px;
      @apply w-full border-0 border-r border-gray-200 border-solid;
    }
  }

  #app > main {
  }
}

@media screen and (min-width: 1300px) {
  #app > main {
    @apply mx-auto;
  }
}

.el-loading-spinner .circular svg {
  display: inline;
}

.has-error {
  .el-input {
    .el-input__inner {
      border-color: red;
    }
  }
}

.el-table .warning-row {
  background: oldlace;
}

* {
  box-sizing: border-box;
}

html,
body,
#app {
  height: 100%;
}

.el-input.round .el-input__inner {
  min-width: 400px;
  @apply rounded-full;
}

main {
}
</style>
