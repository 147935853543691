import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    redirect: { name: "products" },
    meta: { requiresAuth: true },
  },
  {
    path: "/sales",
    name: "sales",
    component: () =>
      import(/* webpackChunkName: "sales" */ "../views/Sales/index.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: ":orderId",
        name: "order-view",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "sales" */ "../views/Sales/orderView.vue"
          ),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/products/",
    name: "products",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Products/list.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/orders/",
    name: "orders",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Orders.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Users.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/checkout/",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/checkout.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/products-all/",
    name: "products-all",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Products/list-all.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/products/:id",
    name: "Product",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/Products/item.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/branch",
    name: "branches",
    component: () =>
      import(/* webpackChunkName: "branch" */ "../views/Branch/list.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/analytics/shop",
    name: "analytics",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Analytics.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/analytics/kaspi-pay",
    name: "kaspi-pay",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/KaspiPay.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/proxies",
    name: "proxies",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Proxies.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  routes,
});

import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorageService.getAccessToken() == null) {
      next({
        path: "/auth",
        params: { nextUrl: to.fullPath },
      });
    }
    next();
  } else {
    next();
  }
});

export default router;
