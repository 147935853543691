<script>
import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
    links() {
      const links = [
        {
          title: "Товары",
          icon: "el-icon-files",
          route: { name: "products" },
        },
        {
          title: "Корзина",
          icon: "el-icon-shopping-bag-1",
          route: { name: "checkout" },
        },
        {
          title: "История заказов",
          icon: "el-icon-receiving",
          route: { name: "orders" },
        },
      ];

      if (this.user.id === 1) {
        links.push({
          title: "Пользователи",
          icon: "el-icon-user",
          route: { name: "users" },
        });
      }

      links.push({
        title: "Выход",
        icon: "el-icon-back",
        action: "logout",
      });

      return links;
    },
    productsCountInBasket() {
      return this.$store.getters["productsCountInBasket"];
    },
  },
  methods: {
    handleAction(command) {
      console.log(command);
      if (command === "logout") {
        localStorageService.clearToken();
        this.$router.push({ name: "auth" });
      } else if (["orders", "users"].includes(command)) {
        this.$router.push({ name: command });
      }
    },
  },
};
</script>

<template>
  <div class="navbar-wrapper">
    <div class="logo">
      <router-link :to="{ name: 'products' }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1500.79 1500.79"
          style="enable-background: new 0 0 1700.79 1700.79"
          xml:space="preserve"
        >
          <g>
            <g>
              <g>
                <path
                  class="st0"
                  d="M925.93,694.58h-15.77l-8.51,18.62c-0.01-0.01-0.01-0.01-0.02-0.02c3.94,3.32,7.45,7.03,10.55,11.12 c-0.03-0.04-0.06-0.09-0.09-0.13l5.85-13.07l36.38,81.31l5.8,12.96l12.27,27.43h-60.07l-5.8,12.96h71.66h12.7h4.14L925.93,694.58 z"
                />
                <path
                  class="st0"
                  d="M864.88,829.66l10.87-24.29l5.8-12.96l22.96-51.32c-0.54-1.05-1.09-2.09-1.69-3.11 c-2.23-3.76-4.85-7.17-7.81-10.27L847.4,831.87C853.62,831.71,859.45,830.98,864.88,829.66z"
                />
              </g>
              <g>
                <polygon
                  class="st0"
                  points="787.18,837.39 845.8,706.37 832.35,702.7 778.87,819.28 731.52,716.07 721.66,694.58 718.93,694.58 706.67,694.58 705.75,694.58 705.75,845.75 721.08,845.75 721.08,726.81 770.56,837.39 "
                />
              </g>
              <path
                d="M916.64,730.86c-0.72-1.21-1.48-2.38-2.27-3.53c-0.71-1.03-1.44-2.04-2.19-3.02c-3.1-4.09-6.61-7.8-10.55-11.12 c-0.82-0.69-1.68-1.36-2.54-2.02c-0.33-0.25-0.66-0.51-1-0.76c-0.74-0.55-1.5-1.09-2.27-1.62c-0.6-0.41-1.21-0.81-1.83-1.2 c-0.59-0.38-1.18-0.77-1.79-1.13c-1.29-0.78-2.61-1.54-3.97-2.25c-1.52-0.8-3.07-1.55-4.66-2.25c-11.08-4.9-23.67-7.36-37.78-7.36 h0h-61.55v80.58l15.98-34.83V708.4h44.7c13.39,0,25.02,2.63,34.88,7.88c1.38,0.73,2.7,1.51,3.99,2.33c0.31,0.19,0.6,0.4,0.9,0.6 c1.01,0.66,2,1.34,2.96,2.05c0.26,0.19,0.5,0.38,0.76,0.58c1.02,0.78,2.01,1.59,2.97,2.43c0.15,0.13,0.29,0.25,0.43,0.38 c1.1,0.99,2.16,2,3.18,3.07c0,0,0,0,0.01,0.01c2.96,3.1,5.58,6.51,7.81,10.27c0.6,1.02,1.16,2.06,1.69,3.11c0,0,0,0,0,0.01 c0.4,0.78,0.76,1.58,1.12,2.38c0.16,0.36,0.33,0.71,0.49,1.08c0.31,0.72,0.58,1.45,0.86,2.18c0.18,0.48,0.36,0.95,0.53,1.43 c0.24,0.68,0.45,1.37,0.67,2.06c0.17,0.57,0.34,1.14,0.5,1.71c0.18,0.65,0.35,1.3,0.5,1.96c0.16,0.66,0.3,1.33,0.44,2 c0.12,0.61,0.25,1.22,0.35,1.83c0.13,0.77,0.25,1.56,0.35,2.34c0.08,0.54,0.16,1.08,0.22,1.63c0.11,0.96,0.19,1.94,0.26,2.93 c0.03,0.4,0.07,0.79,0.09,1.2c0.08,1.42,0.12,2.86,0.12,4.32c0,12.09-2.74,22.78-8.2,32.07c-5.47,9.29-13.14,16.56-23,21.81 c-1.17,0.62-2.37,1.2-3.59,1.75c-0.99,0.44-2,0.85-3.02,1.24c-0.21,0.08-0.41,0.17-0.62,0.25c-2.48,0.92-5.04,1.71-7.7,2.36 c0,0,0,0,0,0c-5.43,1.33-11.26,2.06-17.48,2.21l-6.35,13.88h4.75h0c0.01,0,0.02,0,0.03,0c2.08,0,4.13-0.06,6.14-0.17 c0.03,0,0.06-0.01,0.09-0.01c2-0.11,3.96-0.27,5.89-0.49c0,0,0,0,0.01,0c1.5-0.17,2.98-0.37,4.45-0.6 c0.71-0.11,1.41-0.24,2.11-0.37c0.93-0.17,1.86-0.35,2.78-0.54c0.68-0.14,1.35-0.29,2.02-0.45c1.12-0.27,2.23-0.56,3.33-0.86 c0.41-0.11,0.83-0.21,1.24-0.33c1.46-0.43,2.9-0.91,4.31-1.42c0.48-0.17,0.95-0.36,1.42-0.53c1.05-0.4,2.09-0.82,3.12-1.26 c0.44-0.19,0.88-0.38,1.32-0.57c1.41-0.64,2.8-1.3,4.16-2.01c12.17-6.4,21.63-15.3,28.4-26.67c6.76-11.37,10.15-24.48,10.15-39.31 C926.79,755.34,923.41,742.23,916.64,730.86z"
              />
            </g>
          </g>
        </svg>
      </router-link>
    </div>
    <ul id="navbar">
      <el-tooltip
        v-for="link in links"
        :key="link.title"
        :content="link.title"
        placement="right"
      >
        <li v-if="link.route">
          <router-link :to="link.route">
            <i :class="link.icon"></i>
            <span>{{ link.title }}</span>
          </router-link>
          <span
            v-if="productsCountInBasket > 0 && link.route.name === 'checkout'"
            class="counter"
            >{{ productsCountInBasket }}</span
          >
        </li>
        <li v-else @click="() => handleAction(link.action)">
          <a>
            <i :class="link.icon"></i>
            <span>{{ link.title }}</span>
          </a>
        </li>
      </el-tooltip>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.st0 {
  fill: #026670;
}
.st1 {
  fill: #f9f9f9;
}
.st2 {
  fill: #ffffff;
}

.navbar-wrapper {
  @apply bg-gray-100 border-0 border-t border-gray-200 border-solid;
  .logo {
    @apply hidden;
  }
  #navbar {
    @apply m-0 p-0 list-none flex justify-around;
    li {
      @apply relative;
      a {
        @apply text-gray-500 no-underline flex flex-col text-center items-center py-1.5 w-full cursor-pointer;
        &.router-link-active {
          @apply text-blue-600;
        }
        i {
          font-size: 25px;
          @apply mb-0.5;
        }
        span {
          font-size: 10px;
          letter-spacing: 0.1px;
        }
      }

      .counter {
        @apply absolute bottom-1 -right-0 text-xs bg-yellow-600 w-4 h-4 rounded-full flex items-center justify-center text-white;
      }
    }
  }
}

@media screen and (min-width: 720px) {
  .navbar-wrapper {
    @apply flex flex-col border-t-0 border-r px-3 pt-5;

    .logo {
      @apply block mb-5;
      a {
        @apply relative flex justify-center items-center w-11 h-11 overflow-hidden;
        svg {
          @apply absolute;
          width: 170px;
          left: -75px;
        }
      }
    }

    #navbar {
      @apply h-full flex-col justify-start border-0 border-t border-solid border-gray-200 pt-5;
      li {
        @apply mb-2.5;
        &:last-child {
          @apply mt-auto;
        }
        a {
          @apply text-gray-800 flex-row w-11 h-11 flex items-center justify-center hover:bg-gray-200 rounded-xl;
          &.router-link-active {
            @apply text-blue-600 bg-gray-200;
          }
          svg {
            font-size: 24px;
            @apply m-0;
          }
          span {
            @apply hidden;
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
