import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    basket: {},
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    initBasket(state) {
      let basket = localStorage.getItem("basket");
      if (basket) {
        basket = JSON.parse(basket);
        state.basket = basket;
      }
    },
    addToBasket(state, product) {
      if (state.basket[product.id]) {
        state.basket[product.id].quantity++;
      } else {
        Vue.set(state.basket, product.id, { product, quantity: 1 });
      }

      localStorage.setItem("basket", JSON.stringify(state.basket));
    },
    setProductCount(state, { productId, count }) {
      state.basket[productId].quantity = count;
      localStorage.setItem("basket", JSON.stringify(state.basket));
    },
    removeProductFromBasket(state, productId) {
      Vue.delete(state.basket, productId);
      localStorage.setItem("basket", JSON.stringify(state.basket));
    },
  },
  getters: {
    isProductInBasket: (state) => (productId) => {
      return productId in state.basket;
    },
    productCountInBasket: (state) => (productId) => {
      return state.basket[productId].quantity;
    },
    productsCountInBasket: (state) => {
      return Object.keys(state.basket).length;
    },
    totalBasket: (state) => {
      return Object.keys(state.basket).reduce((a, b) => {
        console.log(a, b);
        return a + state.basket[b].quantity * state.basket[b].product.price;
      }, 0);
    },
  },
  modules: {},
});
